let debounce = require('lodash.debounce');
import * as YouTubeIframeLoader from 'youtube-iframe';
import { setTimeout } from 'timers';
import { Promise } from 'es6-promise';

export class HeroVideo {
    imageBackground: HTMLElement;
    video: HTMLIFrameElement;
    container: HTMLElement;
    content: HTMLElement;
    pauseButton: HTMLButtonElement;
    playButton: HTMLButtonElement;
    muteButton: HTMLButtonElement;
    unmuteButton: HTMLButtonElement;
    videoWrapper: HTMLElement;
    videoObject: any;
    videoStartTime: number;
    videoEndTime: number;
    endTimeChecker: any;
    breakpoint: number;
    videoEdgeOffset: number;
    isYoutube: boolean;
    mp4: HTMLVideoElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.video = this.container.querySelector('[data-hero-video]') as HTMLIFrameElement;
        this.mp4 = this.container.querySelector('video') as HTMLVideoElement;
        this.content = this.container.querySelector('[data-hero-video-content]') as HTMLElement;
        this.pauseButton = this.container.querySelector('[data-hero-video-pause]') as HTMLButtonElement;
        this.playButton = this.container.querySelector('[data-hero-video-play]') as HTMLButtonElement;
        this.videoWrapper = this.container.querySelector('[data-hero-video-wrapper]') as HTMLElement;
        this.imageBackground = this.container.querySelector('[data-hero-video-image]') as HTMLElement;
        this.videoEdgeOffset = 480; // vertical offset to hide the youtube title and branding

        this.isYoutube = !!this.video;

        if (this.video || this.mp4) {
            this.breakpoint = parseInt(container.getAttribute('data-breakpoint'));
            if (window.outerWidth < this.breakpoint) {
                this.switchToImage();
            }

            if (this.isYoutube) {
                this.setDimensions();
                window.addEventListener('resize', debounce(() => {
                    this.setDimensions();
                }, 50));
            }
        }

        if (this.imageBackground) {
            const src = this.imageBackground.getAttribute('data-image-src');
            const imagePlaceholder = new Image();
            new Promise(resolve => {
                imagePlaceholder.onload = () => {
                    resolve({ src, status: 'ok' });
                    this.imageBackground.classList.add('loaded');
                }
                imagePlaceholder.src = src;
                this.imageBackground.style.backgroundImage = `url(${src})`;
            })
        }

        if (this.video && window.outerWidth >= this.breakpoint && this.isYoutube) {
            this.videoEndTime = parseInt(this.video.getAttribute('data-video-end')) || null;
            this.videoStartTime = parseInt(this.video.getAttribute('data-video-start')) || 0;
            if (!this.video.src) {
                let videoPlayer;
                YouTubeIframeLoader.load(YT => {
                    this.video.src = this.video.getAttribute('data-src');
                    videoPlayer = new YT.Player(this.video.id, {
                        events: {
                            'onReady': this.videoLoaded.bind(this),
                            'onStateChange': this.setTimeChecker.bind(this)
                        }
                    });
                    this.videoObject = videoPlayer;
                });
            }
        }

        if (this.mp4) {
            this.videoWrapper.removeAttribute('aria-hidden');
            this.content.querySelector('.overlay').addEventListener('click', this.playPause.bind(this));
            this.pauseButton.classList.remove('hide');
            this.pauseButton.addEventListener('click', this.playPause.bind(this));
            this.playButton.addEventListener('click', this.playPause.bind(this));
        }
    }

    videoLoaded() {
        if (this.videoObject) {
            const playerState = this.videoObject.getPlayerState();
            if (playerState == 5) {
                // autoplay is blocked abort abort
                this.switchToImage();
            }
            else {
                this.videoWrapper.removeAttribute('aria-hidden');
                this.content.querySelector('.overlay').addEventListener('click', this.playPause.bind(this));
                this.pauseButton.classList.remove('hide');
                this.pauseButton.addEventListener('click', this.playPause.bind(this));
                this.playButton.addEventListener('click', this.playPause.bind(this));
                const duration = this.videoObject.getDuration();
                this.videoEndTime = this.videoEndTime ? Math.min(this.videoEndTime, duration) : duration;
                this.videoStartTime = this.videoEndTime > this.videoStartTime ? this.videoStartTime : 0;
            }
        }
    }

    playPause() {
        if (this.isYoutube) {
            const playerState = this.videoObject.getPlayerState();
            if (playerState == 1) {
                this.videoObject.pauseVideo();
                this.hideUnhide(this.pauseButton, this.playButton);
            }
            else {
                this.videoObject.playVideo();
                this.hideUnhide(this.playButton, this.pauseButton);
            }
        }
        else {
            if (this.mp4.paused) {
                this.mp4.play();
                this.hideUnhide(this.playButton, this.pauseButton);
            }
            else {
                this.mp4.pause();
                this.hideUnhide(this.pauseButton, this.playButton);
            }
        }
    }

    setTimeChecker() {
        if (this.videoObject) {
            const playerState = this.videoObject.getPlayerState();
            if (playerState == 1) { // playing
                clearInterval(this.endTimeChecker);
                this.endTimeChecker = setInterval(this.checkForEnd.bind(this), 1000);
            }
            else if (playerState == 0) { // ended
                this.videoObject.seekTo(this.videoStartTime);
                this.videoObject.playVideo();
            }
            else {
                clearInterval(this.endTimeChecker);
            }
        }
    }

    checkForEnd() {
        const curTime = this.videoObject.getCurrentTime();
        if (curTime >= this.videoEndTime) {
            this.videoObject.seekTo(this.videoStartTime);
        }
    }

    setDimensions() {
        const headerWidth = this.container.offsetWidth;
        const headerHeight = this.container.offsetHeight;
        this.video.style.width = `${Math.max(headerWidth, (headerHeight * 16 / 9))}px`;
        this.video.style.height = `${Math.max((headerHeight + this.videoEdgeOffset), (headerWidth * 9 / 16))}px`;
    }

    switchToImage() {
        this.imageBackground.removeAttribute('aria-hidden');
        this.videoWrapper.setAttribute('aria-hidden', 'true');
    }

    hideUnhide(thingToHide: HTMLElement, thingToUnhide: HTMLElement) {
        thingToHide.classList.add('hide');
        thingToUnhide.classList.remove('hide');
        thingToUnhide.focus();
    }
}